import { makeAutoObservable } from 'mobx';

import { toast } from 'react-toastify';

export type User = {
  name: string;
  address: string;
  email?: string;
};

class GlobalController {
  requestCount = 0;

  user: null | User = null;

  token = '';

  isOpen1cModal: boolean = false;

  get waitForAnswer() {
    return this.requestCount > 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  startRequest = () => {
    this.requestCount += 1;
  };

  stopRequest = () => {
    if (this.requestCount > 0) {
      this.requestCount -= 1;
    }
  };

  setModalOpen(modalType: string, value: boolean) {
    if (modalType === '1c') {
      this.isOpen1cModal = value;
    }
  }

  getUser() {
    return this.user;
  }

  setUser(user: User) {
    this.user = user;
  }

  resetUser() {
    this.user = null;
  }

  getToken() {
    return this.token;
  }

  resetToken() {
    this.token = '';
  }

  setToken(token: string) {
    this.token = token;
  }

  handleError({ error, message }: { error?: any; message?: string }) {
    this.stopRequest();
    const msg = message || error?.response?.data?.message || error?.message || 'Ошибка сервера';
    toast(msg, { type: 'error' });
    console.error(error || msg);
  }
}

const globalController = new GlobalController();
export default globalController;
