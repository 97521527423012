import React, { useEffect, useState } from 'react';
import Checkbox from '../../components/Checkbox';
import Modal from './Modal';
import globalController from '../../mobx/GlobalController';
import { observer } from 'mobx-react-lite';
import { formatPhone } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { userRegister } from '../../utils/httpServices/user';
import { InputField } from './InputField';
// import { QuestionIcon } from '../../components/Icons/Question';
// import { Tooltip } from '../../components/Tooltip';

const taxOptions = ['УСН доход', 'УСН расход', 'ОСНО'];

const initForm = {
  name: '',
  email: '',
  birthday: '',
  address: '',
  tel: '',
  tax_system: taxOptions[0],
};

type FormState = typeof initForm;

const ProfileForm = observer(() => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormState>(initForm);
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [isCheckedPolicy, setIsCheckedPolicy] = useState(false);
  const [isCheckedPSN, setIsCheckedPSN] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [code, setCode] = useState<string>('');
  const [isNeed, setIsNeed] = useState(false);
  // const [isShowPSN, setIsShowPSN] = useState(false);

  const user = globalController.getUser();
  const token = globalController.getToken();

  const handleInputChange = (e: any) => {
    const name = e?.target?.name || e?.name;
    const value = e?.target?.value || e?.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);

    const parts = formData.birthday.split(/[.-/]/);
    const date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    date.setHours(12, 0, 0, 0);
    console.log('date', date);
    const timestamp = date.getTime();
    console.log('timestamp', timestamp);
    const data = {
      phone: +formatPhone(formData.tel),
      birthday: timestamp,
      email: formData.email,
      token,
      tax_system: formData.tax_system,
      psn: isCheckedPSN ? 1 : 0,
      need_buh: isNeed ? 1 : 0,
    };
    const res = await userRegister(data);
    if (res?.sended) {
      // setCode(res.code.toString());
      setIsShowModal(true);
    }
  };

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        address: user.address || '',
        email: '',
        tel: '',
        birthday: '',
        tax_system: taxOptions[0],
      });
    }
  }, [user]);

  useEffect(() => {
    if (!user || !token) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="">
        <InputField
          label="Фамилия Имя Отчество"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Введите ФИО"
          autoComplete="profile-name"
          disabled={true}
        />
        <InputField
          label="Дата рождения"
          name="birthday"
          type="date"
          value={formData.birthday}
          onChange={handleInputChange}
          placeholder="Выбрать дату рождения*"
          min="1900-01-01"
          max={`${new Date().getFullYear() - 1}-12-31`}
          autoComplete="profile-dob"
        />
        <div className="flex gap-6 w-full">
          <InputField
            type="dropdown"
            options={taxOptions}
            className="w-full"
            label="Выбор системы налогообложения"
            name="tax_system"
            value={formData.tax_system}
            placeholder="УСН доход"
            onChoose={(value) => handleInputChange({ name: 'tax_system', value })}
          />
          <div className="flex items-center">
            <Checkbox label="" checked={isCheckedPSN} onChange={setIsCheckedPSN} />
            <div className="text-sm font-medium pl-3 pr-[6px]">ПСН</div>
            {/* <div
              className="relative"
              onMouseEnter={() => setIsShowPSN(true)}
              onMouseLeave={() => setIsShowPSN(false)}
            >
              <QuestionIcon />
              {isShowPSN && (
                <Tooltip className="absolute -top-12 right-0 z-50 whitespace-nowrap">
                  Что это такое?
                </Tooltip>
              )}
            </div> */}
          </div>
        </div>
        <InputField
          label="Адрес"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          placeholder="Введите адрес"
          autoComplete="profile-address"
          disabled={true}
        />
        <InputField
          label="E-Mail"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Введите email"
          autoComplete="profile-email"
        />
        <InputField
          label="Контактный телефон"
          name="tel"
          value={formData.tel}
          onChange={handleInputChange}
          type="tel"
          placeholder="+7 (9**) **-**-***"
          autoComplete="profile-tel"
        />

        <div className="text-sm font-normal text-[#A3A3A3] -mt-5">Потребуется подтверждение</div>
        <div className="mt-6">
          <Checkbox
            label={<div className="flex text-sm font-medium">Мне нужен бухгалтер</div>}
            onChange={setIsNeed}
            checked={isNeed}
          />
        </div>
        <button
          type="submit"
          className="rounded-[7px] bg-[#0A42D3] w-full h-[56px] text-white text-base mt-[42px] mb-5 disabled:opacity-[.4] disabled:cursor-not-allowed"
          disabled={
            formatPhone(formData.tel).length !== 11 ||
            !isCheckedTerms ||
            !isCheckedPolicy ||
            formData.birthday.length !== 10
          }
        >
          Войти
        </button>
        <div className="flex flex-col gap-4">
          <Checkbox
            label={
              <span className="text-[11px]">
                <span onClick={() => setIsCheckedTerms((p) => !p)}>
                  Подтверждаю свое согласие с правилами
                </span>
                <u
                  className="mx-1 text-[#0A42D3] cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open('/politics.docx', '_blank');
                  }}
                >
                  оферты
                </u>

                <span onClick={() => setIsCheckedTerms((p) => !p)}>компании</span>
              </span>
            }
            onChange={setIsCheckedTerms}
            checked={isCheckedTerms}
          />
          <Checkbox
            label={
              <div
                className="inline-flex text-[11px]"
                onClick={() => setIsCheckedPolicy((p) => !p)}
              >
                Подтверждаю свое согласие с политикой ОПД
              </div>
            }
            onChange={setIsCheckedPolicy}
            checked={isCheckedPolicy}
          />
        </div>
        {isShowModal && (
          <Modal code={code} setCode={setCode} setIsShowModal={setIsShowModal} token={token} />
        )}
      </form>
    </>
  );
});

export default ProfileForm;
